import { Component, ReactNode } from 'react';
import { isMobileOnly } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

type ListWrapperProps = {
  children: ReactNode;
};

export const HorizontalScrollStyle = styled.div`
  overflow-y: hidden;
  position: relative;
  width: calc(100vw - 16px);
`;

export const ListWrapperStyle = styled.ul`
  display: flex;
  overflow-x: scroll;
  padding-bottom: 20px;
  overflow-y: hidden;
  list-style: none;
`;

export const Item = styled.li`
  scroll-snap-align: center;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: stretch;

  & > * {
    width: 290px;
  }
`;

const ListWrapper = (props: ListWrapperProps) => {
  return <ListWrapperStyle id="hScroll">{props.children}</ListWrapperStyle>;
};

class HorizontalScroll extends Component<any> {
  componentDidUpdate() {
    if (!isMobileOnly) ReactTooltip.rebuild();
  }

  render() {
    return (
      <HorizontalScrollStyle>
        <ListWrapper>
          {this.props.items.map((data, index) => {
            return (
              <Item key={index} className="item">
                {data}
              </Item>
            );
          })}
        </ListWrapper>
      </HorizontalScrollStyle>
    );
  }
}

export default HorizontalScroll;
