import { faPlay } from '@fortawesome/pro-regular-svg-icons';
import { faPlay as faPlaySolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { IVideoAward } from '@lstv/core/types';
import Avatar from '~/components/Avatar';
import LSTVImage from '~/components/LSTVImage';
import LSTVLink from '~/components/LSTVLink';
import { EventParams } from '~/globals/trackEvent';
import { secsToTimeStr } from '~/utils/LSTVUtils';
import * as S from './index.styles';
import Image from 'next/image';

const touchOnlyDevice = typeof window !== 'undefined' && window.matchMedia('(hover: none)').matches;

enum DisplayMode {
  OnHover,
  Never,
  Always,
}

interface ActiveElementListener {
  (value: HTMLDivElement | undefined): void;
}

/**
 * On touch devices, the user first taps a card to activate it, then taps again
 * to open the link.
 *
 * This is a pub-sub mechanism (a la RxJS BehaviorSubject) for the thumbnail
 * element of the currently active card. It's used to make sure that only one
 * card is active at a time, and to animate the thumbnail of the active card (if
 * no card is active, we decide which one to animate using
 * IntersectionObserver).
 *
 * On devices with a mouse, all of this logic isn't used: the card is never set
 * active, and we're not using IntersectionObserver: we just animate the card on
 * hover.
 */
const activeElementSubject = (() => {
  let activeElement: HTMLDivElement | undefined = undefined;
  const listeners = new Set<ActiveElementListener>();
  return {
    get: () => activeElement,
    set: (value: HTMLDivElement | undefined) => {
      activeElement = value;
      listeners.forEach((listener) => {
        listener(value);
      });
    },
    /**
     * Returns an unsubscribe function.
     */
    subscribe: (listener: ActiveElementListener) => {
      listeners.add(listener);
      return () => {
        listeners.delete(listener);
      };
    },
  };
})();

/**
 * This is used to change thumbnail to an animating gif for a video on mobile,
 * where hover effect isn't available. Since we don't want a lot of videos
 * animating at the same time on tablets, we animate whichever video becomes
 * fully visible (as a result of page being loaded or user scrolling), but only
 * if there is a single such video.
 */
const intersectionObserver =
  typeof window !== 'undefined' && window.matchMedia('(hover: none)').matches
    ? new IntersectionObserver(
        (entries) => {
          const oldFullyVisible = new Set(
            [...observedElements.entries()]
              .filter(([, { intersectionRatio }]) => intersectionRatio === 1)
              .map(([el]) => el)
          );
          // Update ratios stored in observedElements.
          entries.forEach(({ target, intersectionRatio }) => {
            const observedElement = observedElements.get(target as HTMLDivElement);
            if (observedElement) {
              observedElements.set(target as HTMLDivElement, { intersectionRatio, callback: observedElement.callback });
            }
          });
          // If a card is active, that decides which element to animate.
          if (activeElementSubject.get()) {
            return;
          }
          // If there is a single new fully visible element, animate it.
          const currentFullyVisible = [...observedElements.entries()]
            .filter(([, { intersectionRatio }]) => intersectionRatio === 1)
            .map(([el]) => el);
          const newFullyVisible = currentFullyVisible.filter((el) => !oldFullyVisible.has(el));
          if (newFullyVisible.length === 1) {
            [...observedElements.entries()].forEach(([el, { callback }]) => callback(el === newFullyVisible[0]));
          }
        },
        {
          threshold: [0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1],
          // Mobile header.
          rootMargin: '-75px 0px 0px 0px',
        }
      )
    : undefined;

activeElementSubject.subscribe((value) => {
  if (value) {
    [...observedElements.entries()].forEach(([el, { callback }]) => callback(el === value));
  }
});

/**
 * A map where for each thumbnail element that's currently present in the DOM,
 * we store its current intersectionRatio (when known) and a callback to be called to
 * start or stop the animation.
 */
const observedElements = new Map<HTMLDivElement, { intersectionRatio?: number; callback: (active: boolean) => void }>();

/**
 * The ref is assumed to be React.MutableRefObject, not a callback.
 */
// eslint-disable-next-line react/display-name
const ObservableThumbnail = React.forwardRef<
  HTMLDivElement,
  {
    slug?: string;
    thumbnailOrGifUrl: string;
    thumbnailAlt?: string;
    blankTarget?: boolean;
    gifUrl?: string;
    children: ReactNode;
  }
>(({ slug, blankTarget, thumbnailOrGifUrl, thumbnailAlt, gifUrl, children }, ref) => {
  const [animating, setAnimating] = useState(false);
  const typedRef = ref as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    const el = typedRef.current;
    if (intersectionObserver && el) {
      observedElements.set(el, {
        callback: (active) => {
          setAnimating(active);
        },
      });
      intersectionObserver.observe(el);
      return () => {
        intersectionObserver.unobserve(el);
        observedElements.delete(el);
      };
    }
  }, [typedRef]);

  const Container: React.FC = ({ children }) =>
    slug ? (
      <LSTVLink
        onClick={(event) => {
          if (touchOnlyDevice && typedRef.current !== activeElementSubject.get()) {
            activeElementSubject.set(typedRef.current);
            event.preventDefault();
          }
        }}
        className="overlay"
        to={`/${slug}`}
        blankTarget={blankTarget || undefined}
      >
        {children}
      </LSTVLink>
    ) : (
      <div className="overlay">{children}</div>
    );
  return (
    <S.ThumbnailContainer ref={typedRef}>
      <LSTVImage url={animating && gifUrl ? gifUrl : thumbnailOrGifUrl} alt={thumbnailAlt} />
      {/* <Image
        key={animating && gifUrl ? gifUrl : thumbnailOrGifUrl}
        src={animating && gifUrl ? gifUrl : thumbnailOrGifUrl}
        alt={thumbnailAlt}
        layout="fill"
        objectFit="cover"
        priority={true}
      /> */}
      <Container>{children}</Container>
    </S.ThumbnailContainer>
  );
});

const VideoCard = ({
  slug,
  title,
  videoLocation,
  thumbnailUrl,
  vibes,
  videographerName,
  videographerImageUrl,
  gifUrl,
  thumbnailAlt,
  duration,
  awards,
  onClick,
  blankTarget,
}: {
  slug?: string;
  title: string;
  videoLocation?: string;
  videographerName: string;
  thumbnailUrl: string;
  vibes: string[];
  videographerImageUrl?: string;
  gifUrl?: string;
  thumbnailAlt?: string;
  duration?: string;
  awards?: IVideoAward[];
  onClick?: () => void;
  blankTarget?: boolean;
}) => {
  const [thumbnailOrGifUrl, setThumbnailOrGifUrl] = useState(thumbnailUrl);
  const [active, setActive] = useState(false);
  const displayMode = touchOnlyDevice ? (active ? DisplayMode.Always : DisplayMode.Never) : DisplayMode.OnHover;
  const thumbnailRef = useRef();

  useEffect(() => setThumbnailOrGifUrl(thumbnailUrl), [thumbnailUrl]);

  // Notice that we pass unsubscribe function as return value.
  useEffect(
    () =>
      activeElementSubject.subscribe((el) => {
        setActive(el === thumbnailRef.current);
      }),
    []
  );

  const caption = videoLocation ? `${title} | ${videoLocation}` : title;

  return (
    <EventParams subsection="video_card" subsection_slug={slug}>
      <div
        onMouseEnter={() => {
          if (intersectionObserver === undefined && gifUrl) setThumbnailOrGifUrl(gifUrl);
        }}
        onMouseLeave={() => {
          if (intersectionObserver === undefined && gifUrl) setThumbnailOrGifUrl(thumbnailUrl);
        }}
        onClick={() => onClick?.()}
        style={{ minWidth: '0' }}
      >
        <ObservableThumbnail ref={thumbnailRef} {...{ slug, thumbnailOrGifUrl, blankTarget, thumbnailAlt, gifUrl }}>
          {awards && awards.length > 0 && <S.WfaBadge src={awards[0].award_image} />}
          {displayMode !== DisplayMode.Never && (
            <S.StyledBookmarkButton
              className={displayMode === DisplayMode.OnHover ? 'hover' : undefined}
              type="video"
              slug={slug}
            />
          )}
          <div style={{ display: 'flex' }}>
            <S.PlayIconContainer>
              <FontAwesomeIcon className="no-hover" icon={faPlay} size="lg" />
              <FontAwesomeIcon className="hover" icon={faPlaySolid} size="lg" />
            </S.PlayIconContainer>
            <div style={{ flex: 1 }} />
            {!!duration && displayMode !== DisplayMode.Never && (
              <S.DurationContainer className={displayMode === DisplayMode.OnHover ? 'hover' : undefined}>
                {secsToTimeStr(duration)}
              </S.DurationContainer>
            )}
          </div>
        </ObservableThumbnail>
        <S.CaptionContainer>{caption}</S.CaptionContainer>
        <div style={{ display: 'flex', alignItems: 'center', margin: '6px 0 9px' }}>
          <Avatar
            style={{ flex: '0 0 auto' }}
            imageSrc={videographerImageUrl}
            initial={videographerName.slice(0, 1)}
            size="30px"
            fontSize="18px"
          />
          <S.VideographerNameContainer>{videographerName}</S.VideographerNameContainer>
        </div>
        {vibes !== undefined && (
          <div style={{ display: 'flex', marginBottom: '9px', flexWrap: 'wrap', minHeight: 26 }}>
            {vibes.map((name, index) => (
              <S.VibeContainer key={index}>{name}</S.VibeContainer>
            ))}
          </div>
        )}
      </div>
    </EventParams>
  );
};

export default VideoCard;
